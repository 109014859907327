
  import { mapGetters } from 'vuex'
  import MyCheckTimeline from '~/components/marketplace/my_checks/MyCheckTimeline.vue'
  import CandidateInfo from '~/components/marketplace/CandidateInfo.vue'
  import MyCheckDocumentsDialog from '~/components/marketplace/my_checks/MyCheckDocumentsDialog.vue'
  import MyCheckStatusFactory from '~/components/marketplace/my_checks/MyCheckStatusFactory.vue'
  import MyCheckResultFactory from '~/components/marketplace/my_checks/MyCheckResultFactory.vue'
  import GenericDialog from '~/components/marketplace/my_checks/GenericDialog.vue'
  import CheckOrderConfirmed from '~/components/marketplace/my_checks/CheckOrderConfirmed.vue'

  export default {
    name: `MyCheckDetails`,
    components: {
      GenericDialog,
      MyCheckResultFactory,
      MyCheckStatusFactory,
      MyCheckDocumentsDialog,
      CandidateInfo,
      CheckOrderConfirmed,
      MyCheckTimeline
    },
    data() {
      return {
        toggleCancel: false,
        title: {
          Mr: this.$t(`components.genders.m`),
          Ms: this.$t(`components.genders.f`)
        }
      }
    },
    async fetch() {
      await this.$store.dispatch(`marketplace/getMyCheck`, this.$route.params.id)
    },
    async destroyed() {
      await this.$store.commit(`marketplace/defaultSelectedMyCheck`)
    },
    computed: {
      ...mapGetters({
        myCheck: `marketplace/selectedMyCheck`,
        candidateForm: `marketplace/candidateForm`
      })
    },
    methods: {
      async cancelCheck() {
        this.toggleCancel = true
        await this.$store.dispatch(`marketplace/cancelCheck`, this.myCheck.uuid)
        this.toggleCancel = false
      }
    }
  }
